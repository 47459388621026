import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./components/pages/home/home";

import XceedArchitecture from "./components/pages/products/architecture/architecture";

import SelfServiceBI from "./components/pages/products/selfServiceBI/selfServiceBI";
import Pricing from "./components/pages/pricing/pricing";

// Company Related Page Imports

import Partners from "./components/pages/partners/partners";

import Privacy from "./components/pages/legalPages/Privacy";
import ContactUs from "./components/pages/contactUs/ContactUs";
import TermsOfUse from "./components/pages/legalPages/TermsOfUse.js";

import GetInTouch from "./components/pages/getInTouch/GetInTouch";
import AboutUs from "./components/pages/aboutUs/aboutUs";

import React from "react";
import "./styles/styles.scss";
import Navbar from "./components/Navbar/Navbar";

import Footer from "./components/footer/footer";
import Stories from "./components/pages/stories/stories";

import Story from "./components/pages/story/story";
import DataConnector from "./components/pages/products/dataConnector/dataConnector";
import DataCatalogue from "./components/pages/products/dataCatalogue/dataCatalogue";
import ModelCatalog from "./components/pages/products/modelCatalog/modelCatalog";
import DataEngineeringAndMl from "./components/pages/products/dataEngineeringAndMl/dataEngineeringAndMl";
import SQLEditorView from "./components/pages/products/sqlEditor/sqlEditorView";
import SmartSearch from "./components/pages/products/smartSearch/smartSearch";
import AIAgentView from "./components/pages/products/aiAgents/aiAgentView";

import DataEngineer from "./components/pages/role/dataEngineer/dataEngineer";
import DataScientist from "./components/pages/role/dataScientist/dataScientist";
import DataAnalyst from "./components/pages/role/dataAnalyst/dataAnalyst";
import "./globalVars";
import Page404 from "./components/pages/page404/page404";
import ScrollToTop from "./components/scrollToTop/scrollToTop";

import "./App.css";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch preserveScrollPosition={false}>
          <Route path="/" exact component={Home} />
          <Route
            path="/products/architecture"
            exact
            component={XceedArchitecture}
          />
          <Route
            path="/products/data-connectors"
            exact
            component={DataConnector}
          />
          <Route
            path="/products/data-preparation"
            exact
            component={DataEngineeringAndMl}
          />
          <Route
            path="/products/data-catalog"
            exact
            component={DataCatalogue}
          />
          <Route
            path="/products/model-catalog"
            exact
            component={ModelCatalog}
          />
          <Route path="/role/data-engineer" exact component={DataEngineer} />
          <Route path="/role/data-scientist" exact component={DataScientist} />
          <Route path="/role/data-analyst" exact component={DataAnalyst} />
          <Route
            path="/products/self-service-bi"
            exact
            component={SelfServiceBI}
          />
          <Route
            path="/products/smart-ai-discovery-and-search"
            exact
            component={SmartSearch}
          />
          <Route
            path="/products/xceed-analytics-sql-Workbench"
            exact
            component={SQLEditorView}
          />
          <Route path="/products/ai-agents" exact component={AIAgentView} />

          <Route path="/products/pricing" exact component={Pricing} />

          <Route path="/partners" exact component={Partners} />

          <Route path="/contact-us" exact component={ContactUs} />

          <Route path="/privacy" exact component={Privacy} />
          <Route path="/terms" exact component={TermsOfUse} />

          <Route path="/stories" exact component={Stories} />

          <Route path="/story/:title" exact component={Story} />

          <Route path="/get-in-touch" exact component={GetInTouch} />
          <Route path="/get-started" exact component={GetInTouch} />
          <Route path="/about-us" exact component={AboutUs}></Route>

          <Route
            path="/blogs"
            component={() => {
              window.location.href = window.__BlogUrl__;
              return null;
            }}
          />

          <Route path="*" exact component={Page404} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
